<template>
  <div class='vision-content'>
    <ul class='list'>
      
      <li v-for='(item,index) in visionData' :key = index  v-show='ins==index'
      v-touch:up="swipeUp" 
      v-touch:left="swipeLeft"
      v-touch:right="swipeLeft"
      v-touch:down="swipeDown"
        class='flex flex-align-items flex-justify-content'
        >
          <div :class="currentItem.class=='type1'?
          'type1':currentItem.class=='type2'?
          'type2':currentItem.class=='type3'?'type3':'type4'"
          >
            <i class='icon-E iconfont'
              :style="{fontSize:(width*item.scale)+'px'}" ></i>
          </div>
            
      </li>
    </ul>
    <div class='flex'>
      <div class='flex-1'>
        <img src="../assets/imgs/up-icon.png" alt="" @click='swipeUp'>
      </div>
      <div class='flex-1'>
        <img src="../assets/imgs/down-icon.png" alt="" @click='swipeDown'>
      </div>
      <div class='flex-1'>
        <img src="../assets/imgs/cancel.png" alt="" @click='cancel'>
      </div>
      <div class='flex-1'>
        <img src="../assets/imgs/left-icon.png" alt="" @click='swipeLeft'>
      </div>
      <div class='flex-1'>
        <img src="../assets/imgs/right-icon.png" alt="" @click='swipeRight'>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      width:63,
      innerIns:0,
      n:5,
      infoMessage:'',
      currentItem:{},
      timer:null,
      isNextStep: true
    };
  },
  props:{
    visionData:{
      type:Array,
      default(){
        return []
      }
    },
    ins:{
      type:Number,
      default: 0
    }
  },
  created(){
    this.currentItem = this.$rowList[Math.round(Math.random()*27)];
    console.log(this.visionData)
  },
  methods: {
    swipeUp(){
      this.$emit('onSwipeUp')
    },
    swipeLeft(){
      this.$emit('onSwipeLeft')
    },
    swipeRight(){
      this.$emit('onSwipeRight')
    },
    swipeDown(){
      this.$emit('onSwipeDown')
    },
    cancel(){
      this.$emit('cancel')
    }
  },
};
</script>



<style lang="less">
@import "../assets/less/index.less";
.vision-content{
  height: 66%;
  
}
.el-row{
  text-align: center;
  font-size: 20px;
}
.list{
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #fff;
  li{
    height: 100%;
    width: 100%;
    // padding-top: 50%;
    overflow: hidden;
  }
}
.type2{
  transform:rotate(90deg)
}
.type3{
  transform:rotate(180deg)
}
.type4{
  transform:rotate(270deg)
}
@font-face {
  font-family: "iconfont"; /* Project id 3432960 */
  src: url('//at.alicdn.com/t/font_3432960_zj3gwsj4dq.woff2?t=1653633137581') format('woff2'),
       url('//at.alicdn.com/t/font_3432960_zj3gwsj4dq.woff?t=1653633137581') format('woff'),
       url('//at.alicdn.com/t/font_3432960_zj3gwsj4dq.ttf?t=1653633137581') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-E:before {
  content: "\e601";
}
</style>