<template>
  <div id="hasTitleCanvas" class="header-box">
    <!-- <div class="left-icon">
     
    </div> -->
    <div class="header-tittle">
      <div v-if="showback" @click="back" class="icon-back"></div>
      <div v-if="isShowTitle">{{tittle}}</div>
    </div>
    <div style="color: #000">
      <slot name="tab"></slot>
    </div>
    <div class="right-icon" style="color: #000">
      <slot name="right-icon"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    isShowTitle: {
      type: Boolean,
      default: true
    },
    tittle: {
      type: String,
      default: ''
    },
    showback: {
      type: Boolean,
      default: true
    },
  },
  created(){
  },
  methods: {
    back() {
      this.$emit('back')
    }
  },
}
</script>

<style lang="less" scoped>
@import "../assets/less/index.less";
.header-box{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  .h(100);
  .lh(100);
  background-color: #fff;
  color: @base-header-color;
  .fs(@base-header-size);
  display: flex;
  justify-content: space-between;
  z-index: 1;
  border-bottom: 1px solid #ddd;
  .left-icon{
    position: relative;
    flex: 1;
    
  }
  .icon-back{
    position: absolute;
    display: inline-block;
    .w(20);
    .h(40);
    .left(25);
    .top(30);
    background-image: url("../assets/imgs/icon_back.png");
    background-size: 100% 100%;
  }
  .header-tittle{
    // flex: 4;
    color: #000000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    // width: 100%;
    text-align: left;
    .ml(60)
  }
  
  .right-icon{
    position: relative;
    // flex: 1;
    .mr(60);
    img{
      .w(35);
      .h(35);
      .mt(25);
    }
  }
}
</style>
